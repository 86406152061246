


export function processItemData(data) {
    var processed = {
        getOption: function (key) {
            key = key.sort();
            return this.optionDict[key];
        }
    };

    processed.id = data.id;

    processed.currentPrice = 0.0;

    processed.options = {};
    processed.variants = {};

    processed.optionDict = {};

    processed.available = false;

    for (const option of data.options) {
        for (const value of option.values) {
            if (processed.options[option.type] !== undefined) {
                processed.options[option.type].push(value);
            }
            else {
                processed.options[option.type] = [value];
            }
        }
    }

    var usedOptions = new Set();

    // Compiling all used and available variants, also documents used options
    for (const variant of data.variants) {
        if (variant.is_available && variant.is_enabled) {
            if (variant.is_default) {
                processed.defaultVariant = variant.id;
            }

            const variantAdd = {
                "id": variant.id,
                "sku": variant.sku,
                "price": variant.price,
                "title": variant.title,
                "options": variant.options,
                "images": []
            };

            processed.variants[variant.id] = variantAdd;

            const optionKey = variant.options.sort();
            processed.optionDict[optionKey] = variantAdd;

            processed.available = true;

            for (const optionNum of variant.options) {
                usedOptions.add(optionNum);
            }
        }
    }

    // Removing unused or unavailable options
    for (const optionType of Object.keys(processed.options)) {
        for (let i = processed.options[optionType].length - 1; i >= 0; i--) {
            if (!usedOptions.has(processed.options[optionType][i].id)) {
                processed.options[optionType].splice(i, 1);
            }
        }
    }

    for (const image of data.images) {
        // if (image.position != "front" && image.position != "back") {
        //     continue;
        // }
        for (const variantID of image.variant_ids) {
            if (processed.variants[variantID] !== undefined) {
                processed.variants[variantID].images.push(image.src);
            }
        }
    }

    if (!processed.defaultVariant && Object.keys(processed.variants).length > 0) {
        processed.defaultVariant = Object.keys(processed.variants)[0];
    }

    return processed;
}
