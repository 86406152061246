import { useState } from 'react';

import './Contact.css'

function ContactForm() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    const [sent, setSent] = useState("Send");

    const sendMessage = async () => {
        const data = {
            name: name,
            email: email,
            subject: subject,
            message: message
        }

        const response = await fetch('/api/contact', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })

        if (response.ok) {
            setSent("Sent!");
        }
    }

    return <>
        <div class="Double">
            <input className="Form" placeholder="Name" onChange={(e) => setName(e.target.value)}></input>
            <input className="Form" placeholder="Email" onChange={(e) => setEmail(e.target.value)}></input>
        </div>
        <input className="Form" placeholder="Subject" onChange={(e) => setSubject(e.target.value)}></input>
        <textarea className="Form Message" placeholder="Message" onChange={(e) => setMessage(e.target.value)}></textarea>
        <button onClick={sendMessage}>{sent}</button>
    </>
}

function Contact() {
    return <>
        <h className="ContactHeader">Contact Me!</h>
        <div className="ContactPage">
            <p>
                Have an issue with an order?<br />
                Have a suggestion?<br />
                Like one of my other projects?<br /><br />
                Send me a message!<br /><br />
                Either fill out the form below or email me directly at <a href="mailto:chromacrashbusiness@gmail.com">chromachrashbusiness@gmail.com</a>
            </p>
            <ContactForm />
        </div>
    </>
}

export default Contact;

