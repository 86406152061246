import React, { useRef, useState, useEffect, useCallback, useReducer } from 'react';
import CartMenu from './Cart.js';
import './Checkout.css';
import { approveShipping } from './Transactions.js'
import CountrySelector from './CountrySelector.js'

import axios from 'axios';


function ShippingForm() {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");

    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    const [country, setCountry] = useState("US");
    const [region, setRegion] = useState("");

    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");

    const [city, setCity] = useState("");
    const [zip, setZip] = useState("");

    useEffect(() => {
        if (localStorage.getItem("shipping")) {
            const shipping = JSON.parse(localStorage.getItem("shipping"));
            if (shipping.first_name) { setFirstName(shipping.first_name) }
            if (shipping.last_name) { setLastName(shipping.last_name) }
            if (shipping.email) { setEmail(shipping.email) }
            if (shipping.phone) { setPhoneNumber(shipping.phone) }
            if (shipping.country) { setCountry(shipping.country) }
            if (shipping.region) { setRegion(shipping.region) }
            if (shipping.address1) { setAddress1(shipping.address1) }
            if (shipping.address2) { setAddress2(shipping.address2) }
            if (shipping.city) { setCity(shipping.city) }
            if (shipping.zip) { setZip(shipping.zip) }
        }
    }, []);

    useEffect(() => {
        localStorage.setItem("shipping", JSON.stringify({
            "first_name": firstName,
            "last_name": lastName,
            "email": email,
            "phone": phoneNumber,
            "country": country,
            "region": region,
            "address1": address1,
            "address2": address2,
            "city": city,
            "zip": zip
        }));
    }, [firstName, lastName, email, phoneNumber, country, region, address1, address2, city, zip])

    const sendToPayment = () => {
        approveShipping()
            .then((response) => {
                if (response) {
                    window.open("/payment", "_self");
                }
            })
            .catch((err) => {
                console.error(err);
            })
    }

    return <div className="Payment">
        <h>Shipping</h>
        <p className="Error"></p>
        <div className="CardForm Shipping">
            <div className="SmallInfo">
                <input placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)}></input>
                <input placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)}></input>
            </div>

            <input placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}></input>
            <input placeholder="Phone Number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}></input>
            <CountrySelector onChange={(e) => { setCountry(e.target.value) }}></CountrySelector>
            {/* <input placeholder="Country" value={country} onChange={(e) => setCountry(e.target.value)}></input> */}
            <input placeholder="Region / State" value={region} onChange={(e) => setRegion(e.target.value)}></input>
            <input placeholder="Address 1" value={address1} onChange={(e) => setAddress1(e.target.value)}></input>
            <input placeholder="Address 2" value={address2} onChange={(e) => setAddress2(e.target.value)}></input>

            <div className="SmallInfo">
                <input placeholder="City" value={city} onChange={(e) => setCity(e.target.value)}></input>
                <input placeholder="Zip" value={zip} onChange={(e) => setZip(e.target.value)}></input>
            </div>

            <button onClick={sendToPayment}>Continue To Payment</button>
        </div>
    </div >
}


function Shipping() {
    return <div className="Checkout">
        <CartMenu includeCheckout={false} editable={false}></CartMenu>
        <div className="CheckoutData">
            <ShippingForm></ShippingForm>
        </div>
    </div>
}

export default Shipping;