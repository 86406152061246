
import React, { useRef, useState, useEffect, useCallback, useReducer } from 'react';
import { useParams } from 'react-router-dom';

import CartItem from './CartItem'

import './Order.css'

function OrderPage() {
    const { id } = useParams();

    const [orderData, setOrderData] = useState(null);

    const [orderState, setOrderState] = useState("Created");

    const [subtotals, setSubTotals] = useState({});
    const [shippingAmount, setShippingAmount] = useState(0);

    const processOrderData = async (response) => {
        const data = await response.json();

        setOrderData(data);
    }

    useEffect(() => {
        fetch("/api/order/track/" + id)
            .then(async (response) => {
                processOrderData(response);
            })
            .catch((err) => {
                console.error(err);
            })
    }, []);

    useEffect(() => {
        if (!orderData) {
            return;
        }

        if (orderData.status == 'on-hold' || orderData.status == 'sending-to-production') {
            setOrderState('Created');
        }
        if (orderData.status == 'in-production') {
            setOrderState('Production');
        }
        if (orderData.status == 'fulfilled') {
            setOrderState('Shipped');
        }

        if (orderData.status == 'canceled' || orderData.status == 'has-issues') {
            setOrderState('Cancelled');
        }

        setShippingAmount(orderData.total_shipping / 100);
    }, [orderData]);

    const calculateSubTotal = () => {
        var sum = 0.0;
        for (const itemID of Object.keys(subtotals)) {
            for (const variantID of Object.keys(subtotals[itemID])) {
                if (!subtotals[itemID][variantID]) {
                    continue;
                }
                sum += subtotals[itemID][variantID];
            }
        }
        return sum;
    }

    const calculateSalesTax = () => {
        return (calculateSubTotal() + shippingAmount) * 0.0811;
    }

    const updateSubTotal = (itemID, variantID, subtotal) => {
        var newTotals = Object.assign({}, subtotals);

        if (!newTotals[itemID]) {
            newTotals[itemID] = {};
        }

        newTotals[itemID][variantID] = subtotal;
        setSubTotals(newTotals);
    }

    if (!orderData) {
        return <></>
    }

    return <>
        <div className="OrderPage">
            <div className="CartMenu">
                {
                    orderData.line_items.map((item) => {
                        return <CartItem key={item.product_id} itemID={item.product_id} variantID={item.variant_id} amount={item.quantity}
                            subTotalFunction={updateSubTotal} editable={false}></CartItem>
                    })
                }
                <div className="Subtotal">
                    <p>Subtotal: <span>{"$" + calculateSubTotal().toFixed(2)}</span></p>
                    <p>Shipping: <span>{(shippingAmount > -1 ? "$" + shippingAmount : "???")}</span></p>
                    <p>Taxes: <span>{(shippingAmount > -1 ? "$" + (calculateSalesTax()).toFixed(2) : "???")}</span></p>
                    <p>Total: <span>{(shippingAmount > -1 ? "$" + (shippingAmount + calculateSubTotal() + calculateSalesTax()).toFixed(2) : "???")}</span></p>
                </div>
            </div>
            <div className="OrderTracking">
                <h>Your Order</h><br></br>
                <h className="SubHeader">#{orderData.id}</h>
                <div className="ProgressTracker">
                    <div className={"Progress " + orderState}></div>
                    {orderState != 'Cancelled' ? <>
                        <p>
                            * Created
                        </p>
                        <p>
                            * In Production
                        </p>
                        <p>
                            * Shipped
                        </p>
                        <p>
                            * Delivered
                        </p>
                    </>
                        : <><p>* {orderState}</p></>}
                </div>
                <h>Carrier: {orderData.shipments[0].carrier}</h><br></br>
                <h className="SubHeader">Tracking #: {orderData.shipments[0].number}</h><br></br><br></br>
                <h className="SubHeader">You can track your shipment <a href={orderData.shipments[0].url}>here</a>.</h>
            </div>
        </div >
    </>
}

export default OrderPage;
