import axios from 'axios';


function compileOrder() {
    const shippingData = JSON.parse(localStorage.getItem("shipping"));

    var lineItems = []
    const cart = JSON.parse(localStorage.getItem("cart")) || {};

    for (const itemID of Object.keys(cart)) {
        for (const variantID of Object.keys(cart[itemID])) {
            lineItems.push({
                "product_id": itemID,
                "variant_id": variantID,
                "quantity": cart[itemID][variantID]
            });
        }
    }

    const printifyShippingData = {
        "line_items": lineItems,
        "address_to": shippingData
    }

    return printifyShippingData;
}


export async function requestShipping(printifyShippingData) {
    return await axios.post('/api/order/shipping', printifyShippingData, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}


function validateEmail(email) {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
}

function validatePhone(phone) {
    return String(phone)
        .toLowerCase()
        .match(
            /^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/ig
        );
}


export async function approveShipping() {
    const shippingData = JSON.parse(localStorage.getItem("shipping") || {});
    if (!shippingData) {
        return false;
    }

    if (!shippingData.first_name || !shippingData.last_name) {
        return false;
    }

    if (!validateEmail(shippingData.email)) {
        return false;
    }

    if (!validatePhone(shippingData.phone)) {
        return false;
    }

    if (!shippingData.country) {
        return false;
    }

    if (!shippingData.address1) {
        return false;
    }

    if (!shippingData.city || !shippingData.zip) {
        return false;
    }

    const printifyShippingData = compileOrder();

    const response = await requestShipping(printifyShippingData);
    return response;
}

export async function createOrder() {
    try {
        return fetch("/api/order/create", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(compileOrder())
        })
            .then((response) => response.json())
            .then((order) => {
                return order.id;
            })
            .catch((err) => {
                console.error(err);
            })

        // const orderData = response.data;

        // if (orderData.id) {
        //     return orderData.id;
        // }
        // else {
        //     const errorDetail = orderData?.details?.[0];
        //     const errorMessage = errorDetail
        //         ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
        //         : JSON.stringify(orderData);

        //     throw new Error(errorMessage);
        // }
    }
    catch (error) {
        console.error(error);
        return "AHHH";
    }
}

export async function onApproveCallback(data, actions) {
    var errorData = {
        ok: false
    };
    try {
        const response = await axios.post("api/order/process/" + data.orderID, null, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        const orderData = response.data;

        const transaction = orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
            orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];
        const errorDetail = orderData?.details?.[0];

        if (errorDetail?.issue === "INSTRUMENT_DECLINED" && !data.card && actions) {
            return actions.restart();
        }
        else if (errorDetail || !transaction || transaction.status === "DECLINED") {
            let errorMessage;
            if (transaction) {
                errorData.code = 0;
                errorMessage = `Transaction ${transaction.status}: ${transaction.id}`;
            }
            else if (errorDetail) {
                errorData.code = 1;
                errorMessage = `${errorDetail.description} (${orderData.debug_id})`
            }
            else {
                errorData.code = 2;
                errorMessage = JSON.stringify(orderData);
            }

            errorData.message = errorMessage;

            throw new Error(errorMessage);
        }
        else {
            errorData.ok = true;
            return errorData;
        }
    } catch (error) {
        console.error(error);
        return errorData;
    }
}

