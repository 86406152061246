import './App.css';
import './Shop.css';
import Page from './Shop.js'
import './ItemPage.css';
import ItemPage from './ItemPage.js'
import { useState } from 'react';
import './Cart.css';
import CartMenu from './Cart.js';
import Shipping from './Shipping.js';
import Payment from './Payment.js';
import About from './About.js';
import Contact from './Contact.js';
import OrderPage from './Order.js'

// import { preload } from 'react-dom';

import {
	BrowserRouter,
	Routes,
	Route
} from 'react-router-dom';

function Header() {
	const [cartOpen, toggleCart] = useState(false);

	const location = window.location.href;

	function handleCart() {
		const endPath = location.slice(location.lastIndexOf("/"), location.length);
		if (endPath == "/shipping" || endPath == "/payment") {
			return;
		}
		toggleCart(!cartOpen);
	}

	return <>
		<header className="Header">
			<h>
				<a href="/">CHROMA CRASH</a>
			</h>

			<a href="/">
				<img className="Logo" src={require("./assets/munelogo.gif")}></img>
			</a>

			<div className="CartIcon">
				<img onClick={handleCart} src={require("./assets/cart.png")}></img>
			</div>

			<div className="Dither"></div>

			<div className={"Cart" + (cartOpen ? " OpenCart" : "")}>
				<h>CART</h>
				<div className="CartContainer">
					<CartMenu includeCheckout={true} includeShipping={false}></CartMenu>
				</div>
			</div>
		</header>
	</>
}

function Footer() {
	return <>
		<footer className="Footer">
			<a href="/contact">Contact</a>
			<a href="/about">About</a>
			<div className="Dither Bottom">
			</div>
		</footer>
	</>
}

function App() {
	// preload("https://www.shadertoy.com/embed/McfyWf?gui=false&t=10&paused=false&muted=false", { as: "document" });

	return (
		<BrowserRouter>
			<div className="App">
				<div className="Shader"></div>
				{/* <iframe className="Shader" src="https://www.shadertoy.com/embed/McfyWf?gui=false&t=10&paused=false&muted=false"></iframe> */}
				<Routes>
					<Route path="/" element={<Page />} />
					<Route path="/item/:id" element={<ItemPage />} />
					<Route path="/order/:id" element={<OrderPage />} />
					<Route path="/shipping" element={<Shipping />} />
					<Route path="/payment" element={<Payment />} />
					<Route path="/contact" element={<Contact />} />
					<Route path="/about" element={<About />} />
					<Route path="/review" />
				</Routes>
				<Footer></Footer>
				<Header></Header>
			</div>
		</BrowserRouter>
	);
}

export default App;
