import './Shop.css';
import './Item.css';
import { processItemData } from './Process'
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import collectionData from './collections.json'

// Garbage code ngl but it was the first React code I ever wrote so y'know

function modulo(n1, n2) {
    return ((n1 % n2) + n2) % n2;
}

function Options({ data }) {
    const options = data.values.filter((option) => option.is_available);

    if (options.length < 2) {
        return <></>
    }

    return <>
        <p>
            {data.type + " " + options.length}
        </p>
    </>
}

function Item({ id, index, currentIndex, scrollFunction }) {
    const collectionItem = collectionData.items[id];
    const [price, setPrice] = useState("");
    const [imageURL, setImageURL] = useState(require("./assets/throbber.gif"));

    useEffect(() => {
        axios.get(`/api/item/${id}`, {})
            .then((response) => {
                const processed = processItemData(response.data);
                var found = false;
                if (processed.defaultVariant) {
                    if (processed.variants[processed.defaultVariant]) {
                        setImageURL(processed.variants[processed.defaultVariant].images[0]);
                        const priceText = processed.variants[processed.defaultVariant].price;
                        setPrice("$" + (priceText / 100).toFixed(2));
                        found = true;
                    }
                }

                if (!found) {
                    setImageURL(require("./assets/outofstock.png"));
                    setPrice("Sold Out");
                }
            })
            .catch((err) => {
                console.error(err);
            })
    }, []);

    const handleClick = (event) => {
        if (index !== currentIndex) {
            scrollFunction(index);
            event.preventDefault();
        }
    }

    return <>
        <a href={"/item/" + id} onClick={handleClick} className={"Item" + (index === currentIndex ? " Open" : "")}>
            <div>
                <img alt={"Image of a " + collectionItem.displayName + " designed with pixel art"} src={imageURL}></img>
            </div>
            <h>{collectionItem.displayName}</h>
            <h>{price}</h>
            <p>{collectionItem.shortDescription}</p>
        </a>
    </>
}

class Collection extends React.Component {
    constructor(props) {
        super(props);

        this.scrollRef = React.createRef();

        this.name = props.name;
        this.collection = collectionData.collections[props.name];

        this.length = this.collection.length;

        this.state = {
            index: 0
        }
    }

    handlePrevious() {
        const newIndex = modulo(this.state.index - 1, this.length);
        this.setIndex(newIndex);
    }

    handleNext() {
        const newIndex = modulo(this.state.index + 1, this.length);
        this.setIndex(newIndex);
    }

    setIndex(newIndex) {
        this.setState({ index: newIndex });
        const pageOffset = (window.innerWidth > 1100) ? -1 : 0;
        this.scrollRef.current.scrollLeft = (newIndex + pageOffset) * 384;
    }

    render() {
        return <>
            <div className="Collection">
                <div className="ScrollButtons">
                    <button onClick={this.handlePrevious.bind(this)}>&lt;</button>
                    <h>{this.name}</h>
                    <button onClick={this.handleNext.bind(this)} className="Right">&gt;</button>
                </div>
                <div ref={this.scrollRef}>
                    {
                        this.collection.map((data, index) => {
                            return <Item id={data} index={index} currentIndex={this.state.index} scrollFunction={this.setIndex.bind(this)}></Item>
                        })
                    }
                </div>
            </div>
        </>
    }
}

function Page() {
    return <div className="Page">
        <Collection name="FEATURED"></Collection>
        <Collection name="SHIRTS"></Collection>
        <Collection name="STICKERS"></Collection>
        <Collection name="HATS"></Collection>
        <Collection name="POSTERS"></Collection>
    </div>
}

export default Page;