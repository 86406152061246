import React, { useRef, useState, useEffect, useCallback, useReducer } from 'react';

import CartItem from './CartItem.js'

function CartMenu({ includeCheckout, includeShipping, shippingAmount, cascadeUpdate, editable = true }) {
    const [currentCart, setCurrentCart] = useState(JSON.parse(localStorage.getItem("cart")) || {});
    const [subtotals, setSubTotals] = useState({});

    useEffect(() => {
        function checkCart() {
            try {
                setCurrentCart(JSON.parse(localStorage.getItem("cart")) || {});
            }
            catch {
                setCurrentCart({});
            }
        }

        window.addEventListener("storage", checkCart);

        return () => {
            window.removeEventListener('storage', checkCart);
        };
    }, []);

    useEffect(() => {
        localStorage.setItem("cart", JSON.stringify(currentCart));

        if (cascadeUpdate) {
            cascadeUpdate();
        }

    }, [currentCart]);

    const changeCart = (itemID, variantID, amount) => {
        let newCart = Object.assign({}, currentCart);
        newCart[itemID][variantID] += amount;

        if (newCart[itemID][variantID] <= 0) {
            delete newCart[itemID][variantID];
        }
        if (Object.keys(newCart[itemID]).length <= 0) {
            delete newCart[itemID];
        }

        setCurrentCart(newCart);
    }

    const calculateSubTotal = () => {
        var sum = 0.0;
        for (const itemID of Object.keys(subtotals)) {
            for (const variantID of Object.keys(subtotals[itemID])) {
                if (!subtotals[itemID][variantID]) {
                    continue;
                }
                if (currentCart[itemID]) {
                    if (currentCart[itemID][variantID]) {
                        sum += subtotals[itemID][variantID];
                    }
                }
            }
        }
        return sum;
    }

    const calculateSalesTax = () => {
        return (calculateSubTotal() + shippingAmount) * 0.0811;
    }

    const updateSubTotal = (itemID, variantID, subtotal) => {
        var newTotals = Object.assign({}, subtotals);

        if (!newTotals[itemID]) {
            newTotals[itemID] = {};
        }

        newTotals[itemID][variantID] = subtotal;
        setSubTotals(newTotals);
    }

    const createCart = (editable) => {
        return Object.keys(currentCart).map((itemID) => {
            return Object.keys(currentCart[itemID]).map((variantID) => {
                return <CartItem
                    key={itemID} itemID={itemID} variantID={variantID} amount={currentCart[itemID][variantID]}
                    cartFunction={changeCart} subTotalFunction={updateSubTotal} editable={editable}></CartItem>
            })
        })
    }

    return (
        <div className='CartMenu'>
            {Object.keys(currentCart).length > 0 ?
                <>{createCart(editable)}
                    <div className="Subtotal">
                        <p>Subtotal: <span>{"$" + calculateSubTotal().toFixed(2)}</span></p>
                        {includeShipping ? <>
                            <p>Shipping: <span>{(shippingAmount > -1 ? "$" + shippingAmount : "???")}</span></p>
                            <p>Taxes: <span>{(shippingAmount > -1 ? "$" + (calculateSalesTax()).toFixed(2) : "???")}</span></p>
                            <p>Total: <span>{(shippingAmount > -1 ? "$" + (shippingAmount + calculateSubTotal() + calculateSalesTax()).toFixed(2) : "???")}</span></p>
                        </> : <></>
                        }
                    </div>
                    {includeCheckout ? <a className="CheckoutButton" href="/shipping">Checkout &gt;&gt;</a> : <></>}
                </>
                : <p className="EmptyCart">This seems to be empty :&#40;</p>}
        </div>
    )
}

export default CartMenu;
