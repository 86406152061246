import React, { useRef, useState, useEffect, useCallback, useReducer } from 'react';
import axios from 'axios';
import { processItemData } from './Process.js'

import collectionData
    from './collections.json'

function CartItem({ itemID, variantID, amount, cartFunction, subTotalFunction, editable }) {
    const [icon, setIcon] = useState();
    const [itemData, setItemData] = useState();
    const [subtotal, setSubTotal] = useState(0.0);

    const localData = collectionData.items[itemID];

    const fetchItemData = async () => {
        const result = await axios.get(`/api/item/${itemID}`, {});
        const processed = await processItemData(result.data);
        if (!processed.variants[variantID]) {

        }
        else {
            setIcon(processed.variants[variantID].images[0]);
            setItemData(processed);
        }
    }

    const minusCart = () => {
        cartFunction(itemID, variantID, -1);
        setSubTotal(calculateSubTotal());
    }

    const addCart = () => {
        cartFunction(itemID, variantID, 1);
        setSubTotal(calculateSubTotal());
    }

    useEffect(() => {
        fetchItemData();
    }, []);

    useEffect(() => {
        setSubTotal(calculateSubTotal());
    }, [itemData]);

    useEffect(() => {
        subTotalFunction(itemID, variantID, subtotal);
    }, [subtotal]);

    const calculateSubTotal = () => {
        return itemData ? itemData.variants[variantID].price * amount / 100 : 0.0;
    }

    return (
        <div className="CartItem">
            {itemData && itemData.variants[variantID] ? <>
                <a href={"/item/" + itemID}>
                    <img src={icon}></img>
                </a>
                <div>
                    <p>{localData.displayName + " x " + amount}</p>
                    <p>{itemData.variants[variantID].title}</p>
                </div>
                <div style={{ alignItems: 'flex-end' }}>
                    <p className="ItemTotal">{"$" + subtotal.toFixed(2)}</p>
                    {editable ? <div className="CartAdjust">
                        <button onClick={addCart}>+</button>
                        <button onClick={minusCart}>-</button>
                    </div> : <></>}
                </div>
            </>
                : <></>}
        </div>
    )
}

export default CartItem;