import React, { useRef, useState, useEffect, useCallback, useReducer } from 'react';
import CartMenu from './Cart.js';
import './Checkout.css';

import { approveShipping, createOrder, onApproveCallback } from './Transactions.js'
import {
    PayPalScriptProvider,
    PayPalButtons,
    PayPalCardFieldsProvider,
    PayPalCardFieldsForm,
    usePayPalCardFields
} from "@paypal/react-paypal-js";

import { createPortal } from 'react-dom';

// function SubmitPayment({ onApproval }) {
//     const { cardFields } = usePayPalHostedFields();

//     const cardHolderName = useRef(null);

//     const submitHandler = () => {
//         if (typeof cardFields.submit !== "function") return; // validate that \`submit()\` exists before using it

//         //if (errorMsg) showErrorMsg(false);
//         cardFields
//             .submit({
//                 // The full name as shown in the card and billing addresss
//                 // These fields are optional for Sandbox but mandatory for production integration
//                 cardholderName: cardHolderName?.current?.value,
//             })
//             .then(async (data) => onApproval(data))
//             .catch((err) => {
//                 console.log(err);
//             });
//     };

//     return (
//         <button onClick={submitHandler}>
//             Place Order
//         </button>
//     );
// }


// const Hosted = React.memo(({ id, fieldType, placeholder }) => {
//     const outlineStyle = { "border": "2px inset #EBE9ED", "paddingLeft": "4px", "width": "calc(100% - 4px)" }

//     return (
//         <PayPalHostedField
//             id={id}
//             hostedFieldType={fieldType}
//             style={outlineStyle}
//             options={{
//                 selector: "#" + id,
//                 placeholder: placeholder
//             }}
//             className="CardInput"
//         >

//         </PayPalHostedField>
//     )
// });


function SubmitPayment({ isPaying, setIsPaying }) {
    const { cardFieldsForm, fields } = usePayPalCardFields();

    const handleClick = async () => {
        if (!cardFieldsForm) {
            throw new Error("AHHH");
        }

        const formState = await cardFieldsForm.getState();

        if (!formState.isFormValid) {
            throw new Error("AHHH 2!");
        }

        setIsPaying(true);

        cardFieldsForm.submit().catch((err) => {
            setIsPaying(false);
        })
    }

    return <button
        className={isPaying ? "Paying" : ""}
        onClick={handleClick}
    >
        Place Order
    </button>
}


const CardForm = React.memo(() => {
    const [clientToken, setClientToken] = useState(null);
    const [isPaying, setIsPaying] = useState(false);
    const [message, setMessage] = useState("");

    const initialOptions = {
        "client-id": "test",
        "enable-funding": "venmo,card",
        "disable-funding": "paylater",
        "data-sdk-integration-source": "integrationbuilder_ac",
        "dataClientToken": clientToken,
        components: "card-fields,buttons",
    };

    useEffect(() => {
        (async () => {
            const response = await fetch("/api/token", {
                method: "POST",
            });
            const { client_token } = await response.json();
            setClientToken(client_token);
        })();
    }, []);

    const onApproval = async (data) => {
        const approvalStatus = await onApproveCallback(data);

        if (!approvalStatus.ok) {
            if (approvalStatus.code < 2) {
                setMessage(approvalStatus.errorMessage);
            }
            return;
        }

        const id = data.orderID;

        fetch("/api/order/find/" + id)
            .then(async (response) => {
                const printifyOrder = await response.json();

                if (printifyOrder.id) {
                    window.open("/order/" + printifyOrder.id, "_self");
                    // localStorage.setItem("cart", "{}");
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    if (!clientToken) {
        return <></>
    }

    const cardStyle = { "input": { "font-family": "Arial", "font-size": "16px" } };

    return (
        <PayPalScriptProvider options={initialOptions}>
            <PayPalButtons
                style={{ color: "gold" }}
                createOrder={createOrder}
                onApprove={onApproval}
            ></PayPalButtons>
            {/* <h className="Break"><span>or</span></h> */}
            {/* <PayPalHostedFieldsProvider createOrder={createOrder} styles={cardStyle}>
                <div>
                    <Hosted id='card-number' fieldType='number' placeholder='Card Number'></Hosted>
                    <div className="SmallInfo">
                        <Hosted id='expiration-date' fieldType='expirationDate' placeholder='Expiration Date'></Hosted>
                        <Hosted id='cvv' fieldType='cvv' placeholder='CVV'></Hosted>
                    </div>
                    <div className="">
                        <input
                            id="card-holder"
                            type="text"
                            placeholder="Name on Card"
                            className="CardInput"
                        />
                    </div>
                    <SubmitPayment onApproval={onApproval} />
                </div>
            </PayPalHostedFieldsProvider> */}
            {/* <PayPalCardFieldsProvider
                createOrder={createOrder}
                onApprove={onApproval}
                onError={(err) => {
                    console.error(err);
                }}>
                <PayPalCardFieldsForm />
                <SubmitPayment isPaying={isPaying} setIsPaying={setIsPaying}></SubmitPayment>
            </PayPalCardFieldsProvider> */}
            <p className='Error'>{message}</p>
        </PayPalScriptProvider>
    )
})


const PaymentForm = React.memo(() => {
    return <div className="Payment">
        <h>Payment</h>
        {/* <button className="PayPal">Pay<span className="Second">Pal</span></button>
        <h className="Break"><span>or</span></h> */}
        <div className="CardForm">
            <CardForm />
            {/* <input placeholder="Card Number"></input>
            <div className="SmallInfo">
                <input placeholder="Expiration"></input>
                <input placeholder="CVV"></input>
            </div>
            <input placeholder="Name on Card"></input>
            <input placeholder="Zip Code"></input>
            <button onClick={createOrder}>Place Order</button>
            <p>By clicking Place Order, you agree to <a>Terms and Conditions</a></p> */}
        </div>
    </div>
})

const Payment = React.memo(() => {
    const [shippingAmount, setShippingAmount] = useState(-1);

    const updateShipping = () => {
        approveShipping()
            .then((response) => {
                setShippingAmount(response.data.standard / 100);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    return <div className="Checkout">
        <CartMenu includeCheckout={false} includeShipping={true} shippingAmount={shippingAmount} cascadeUpdate={updateShipping} editable={false}></CartMenu>
        <div className="CheckoutData">
            <PaymentForm></PaymentForm>
        </div>
    </div>
})

export default Payment;