import "./About.css"
import Carousel from './Carousel.js';

function About() {
    return <>
        <h className="AboutHeader">About</h>
        <div className="AboutPage">
            <div className="AboutBlurb Wide">
                <h>About Me</h>
                <img className="Right" src={require("./assets/About/IMG_2952.jpeg")}></img>
                <br></br>
                <p>
                    I'm Dylan. I made this site and all the designs on it. There's me wearing the only mustard version of the burger shirt. ---&gt;
                    <br></br>
                    <br></br>
                    I'm a software developer (Python, C++, C#, GLSL, SQL, R, React, Several others) and I'm currently getting my bachelor's and master's at Missouri State University.
                    <br></br>
                    <br></br>
                    You can find a bunch of my code here: <a href="https://www.github.com/dylan-berndt">Github</a>
                </p>
            </div>
            <br></br>
            <h>Projects (What you're funding)</h>
            <br></br>
            <div className="AboutBlurb">
                <p>
                    <p className="Underlined">Scribble Scramble</p>
                    <img className="Right" src={require("./assets/About/scribble1.png")} />
                    Multiplayer drawing game. Imagine Pictionary but everyone is drawing and guessing all at once at the same time. Also powerups and such.
                    <br /><br />
                    Written with Python and Pyglet, I've been working on it for more than a year now and I still want to release it to Steam sometime.
                    <br /><br />
                    <img className="Left" src={require("./assets/About/scribble3.png")} />
                </p>
            </div>
            <div className="AboutBlurb">
                <p>
                    <p className="Underlined">This Website</p>
                    <img className="Right" src={require("./assets/About/istockphoto-483070230-612x612.jpg")}></img>
                    This website is still kinda in progress. Would love to have a sort of blog section about projects and more selection of designs. Particularly
                    more variants of single items. (Not everyone likes mayo)
                    <br /><br />
                    Written with React and deployed on my own Raspberry Pi Zero 2W with Nginx and Flask.
                </p>
            </div>
            <br></br>
            <h>Previous Projects</h>
            <br></br>
            <div className="AboutBlurb">
                <p>
                    <p className="Underlined">Synth Box</p>
                    <img className="Right" src={require("./assets/About/beat that box.gif")} />
                    Fun little two month project I made in Python. It's a synthesizer with physical little boxes that generate audio and effects.
                    <br /><br />
                    So far this thing is far and away the most downloaded project of mine. Check it out <a href="https://chroma-crash.itch.io/synth-box">here</a>.
                </p>
            </div>
            <div className="AboutBlurb">
                <p>
                    <p className="Underlined">Island (3D Renderer)</p>
                    <img className="Right" src={require("./assets/About/island2.gif")} />
                    Big old mess of a project. C++ and OpenGL 3D renderer with a ton of features and things. Custom command interface, custom model loading
                    (Stupid Assimp wouldn't compile), cascaded shadow mapping, the works.
                    <br /><br />
                    Below is a volumetric cloud render with some Google Earth images for ground.
                    Never really got to finish this project, but you can see the progress <a href="https://www.github.com/dylan-berndt/Island">here</a>.
                    <br /><br />
                    <img className="Left" src={require("./assets/About/island3.png")} />
                </p>
            </div>
            <div className="AboutBlurb">
                <p>
                    Site powered by Printify.
                    Font by <a href="https://fontenddev.com/">jeti.</a>
                </p>
            </div>
        </div>
    </>
}

export default About;

