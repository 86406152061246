import React, { useRef, useState, useEffect, useCallback, useReducer } from 'react';
import { useParams } from 'react-router-dom';

import collectionData from './collections.json';
import { processItemData } from './Process';

function MiniItem({ src, selectIcon, index, currentIndex, selectIndex }) {
    const selectThis = () => {
        selectIcon(src);
        selectIndex(index);
    }

    return <div className={currentIndex != index ? "" : "Chosen"}>
        <img src={src} onClick={selectThis}></img>
    </div>
}

const ZoomedImage = React.forwardRef((props, ref) => {
    const { src } = props;
    const { offset } = props;
    const { opacity } = props;
    return <img src={src} ref={ref} style={{
        position: "absolute",
        left: offset.left,
        top: offset.top,
        opacity: opacity,
        width: '200%',
        height: '200%'
    }}></img>
})

function ItemDisplay({ src }) {
    const containerRef = useRef(null);
    const targetRef = React.createRef();

    const [offset, setOffset] = useState({ left: 0, top: 0 });
    const [opacity, setOpacity] = useState(0);

    const onHover = () => {
        if (src == require("./assets/throbber.gif")) {
            return;
        }
        setOpacity(1);
    }

    const onLeave = () => {
        setOpacity(0);
    }

    const onMove = (e) => {
        const containerRect = containerRef.current.getBoundingClientRect();
        const targetRect = targetRef.current.getBoundingClientRect();

        const xRatio = (targetRect.width - containerRect.width) / containerRect.width;
        const yRatio = (targetRect.height - containerRect.height) / containerRect.height;

        const left = e.pageX - containerRect.left;
        const top = e.pageY - containerRect.top - window.pageYOffset;

        setOffset({
            left: left * -xRatio,
            top: top * -yRatio
        });
    }

    return <div ref={containerRef}
        onMouseEnter={onHover}
        onMouseLeave={onLeave}
        onMouseMove={onMove}
        className="ItemDisplay">
        {opacity == 0 && <img src={src}></img>}
        <ZoomedImage ref={targetRef} offset={offset} opacity={opacity} src={src} />
    </div>
}

function Selections({ setOption, selections, apiData }) {
    if (!apiData) {
        return <></>
    }

    return <> {
        Object.keys(selections).map((optionType) => {
            return <Selection setOption={setOption} currentSelection={selections[optionType]} options={apiData.options[optionType]} optionType={optionType}></Selection>
        })
    }
    </>
}

function Selection({ setOption, currentSelection, options, optionType }) {
    if (options.length < 2) {
        return <></>
    }

    return <div className="Selectors">
        {options.map((option) => {
            const style = optionType === "color" ? { backgroundColor: option.colors[0] } : {};
            const text = optionType === "size" || optionType === "surface" ? option.title : "";
            return <Option currentID={currentSelection} setOption={setOption} optionType={optionType} optionNumber={option.id} style={style} text={text}></Option>
        })}
    </div>
}

function Option({ setOption, optionType, optionNumber, currentID, style, text }) {
    const handleClick = () => {
        setOption(optionType, optionNumber);
    }

    return <button className={currentID == optionNumber ? "Chosen" : ""} onClick={handleClick} style={style}>
        {text}
    </button>
}

function OptionScroll({ scrollDiv, left }) {
    const scrollFunc = () => {
        const dir = left ? -1 : 1;
        scrollDiv.current.scrollLeft += scrollDiv.current.clientWidth * dir;
    }

    return <button onClick={scrollFunc} className={"OptionScroll" + (left ? " Left" : "")}>
        {left ? "<" : ">"}
    </button>
}

function ItemPage() {
    const [apiData, setData] = useState(null);

    const [variantID, setVariantID] = useState(0);
    const [variant, setVariant] = useState(null);

    const [selections, setSelections] = useState({});

    const [price, setPrice] = useState(0);

    const [mainImage, setIcon] = useState(require("./assets/throbber.gif"));
    const [index, setIndex] = useState(0);

    const [added, setAdded] = useState("Add To Cart");

    const [amount, setAmount] = useState(1);

    const { id } = useParams();

    useEffect(() => {
        fetch('/api/item/' + id)
            .then(response => response.json())
            .then(json => setData(processItemData(json)))
            .catch(error => console.error(error));
    }, [id]);

    useEffect(() => {
        if (apiData != null) {

            var defaultSelections = {};
            // 2024 Award for Worst Code Yet Written
            for (const optionNum of apiData.variants[apiData.defaultVariant].options) {
                for (const optionType of Object.keys(apiData.options)) {
                    const optionList = apiData.options[optionType];
                    for (const option of optionList) {
                        if (option.id === optionNum) {
                            defaultSelections[optionType] = optionNum;
                        }
                    }
                }
            }

            setSelections(defaultSelections);
        }
    }, [apiData])

    useEffect(() => {
        if (apiData != null) {
            const newVariant = apiData.variants[variantID];
            if (newVariant) {
                setVariant(newVariant);
                setIcon(newVariant.images[0]);
                setPrice(newVariant.price / 100.0);
            }
        }
    }, [variantID, apiData]);

    useEffect(() => {
        if (apiData == null) {
            return;
        }

        if (Object.keys(selections).length === 0) {
            return;
        }

        const key = Object.keys(selections).map((key) => { return selections[key] });

        const option = apiData.getOption(key);

        if (option) {
            setVariantID(option.id);
        }
    }, [selections, apiData]);

    const setOption = (index, value) => {
        let clone = { ...selections };
        clone[index] = value;
        setSelections(clone);
    }

    const addToCart = () => {
        var currentCart = JSON.parse(localStorage.getItem("cart")) || {};

        if (!currentCart[id]) {
            currentCart[id] = {};
        }

        if (!currentCart[id][variantID]) {
            currentCart[id][variantID] = 0;
        }

        currentCart[id][variantID] += amount;

        localStorage.setItem("cart", JSON.stringify(currentCart));
        window.dispatchEvent(new Event("storage"));

        setAdded("Added!");
    }

    const buyNow = () => {
        addToCart();
        window.open("/shipping", "_self");
    }

    const amountPlus = () => { setAmount(amount + 1) };
    const amountMinus = () => { setAmount(Math.max(1, amount - 1)) };

    const localData = collectionData.items[id];
    document.title = localData.displayName;

    const scrollRef = useRef();

    return <>
        <div className="Page">
            <div className="ItemArea">
                {window.innerWidth <= 800 ? <h className="ItemHeader">{localData.displayName}</h> : <></>}
                <div className="ItemShowcase">
                    <ItemDisplay src={mainImage}></ItemDisplay>
                    <div className="Scrollers">
                        <OptionScroll scrollDiv={scrollRef} left={true} />
                        <OptionScroll scrollDiv={scrollRef} left={false} />
                    </div>
                    <div className="MiniItems" ref={scrollRef}>
                        {variant ? variant.images.map((data, i) => {
                            return <MiniItem src={data} selectIcon={setIcon} index={i} currentIndex={index} selectIndex={setIndex}></MiniItem>
                        }) : <></>}
                    </div>
                </div>
                <div className="ItemInformation">
                    {window.innerWidth > 800 ? <h className="ItemHeader">{localData.displayName}</h> : <></>}
                    <div className="ItemDescription">
                        <p>{localData.shortDescription}</p>
                        <p>{collectionData.snippets[localData.snippet]}</p>

                        <Selections setOption={setOption} selections={selections} apiData={apiData}></Selections>

                        <p>{"$" + (price * amount).toFixed(2)}</p>
                        <div className="CartArea">
                            <div>
                                <button onClick={amountMinus} className="AddCart Amount">-</button>
                                <p>{amount}</p>
                                <button onClick={amountPlus} className="AddCart Amount">+</button>
                            </div>
                            <button className="AddCart" onClick={addToCart}>{added}</button>
                            <button className="AddCart" onClick={buyNow}>Buy Now</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default ItemPage;



